@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  pre, blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  p, h2, h3 {
    orphans: 3;
    widows: 3; }
  thead {
    display: table-header-group; }
  tr, img {
    page-break-inside: avoid; }
  img {
    max-width: 100% !important; }
  h2, h3, h4 {
    page-break-after: avoid; }
  @page {
    margin: 0.5cm; } }

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

img {
  display: block; }

a img {
  border: none; }

/* Gets rid of IE's blue borders */
textarea {
  resize: none; }

input:focus,
select:focus,
textarea:focus,
button:focus {
  outline: none; }

* {
  outline: 0;
  -webkit-font-smoothing: antialiased;
  -webkit-overflow-scrolling: touch; }

*,
:after,
:before {
  box-sizing: border-box; }

html,
body {
  overflow-x: hidden; }

html, body, div, article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  position: relative; }

img,
video,
audio {
  max-width: 100%; }

img,
video {
  height: auto; }

svg {
  max-height: 100%; }

::-moz-focus-inner {
  border: 0;
  padding: 0; }

input[type="radio"],
input[type="checkbox"] {
  vertical-align: middle;
  position: relative;
  bottom: 0.15rem;
  font-size: 115%;
  margin-right: 3px; }

input[type="search"] {
  -webkit-appearance: textfield; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none; }

html,
body {
  font-size: 18px;
  line-height: 1.5; }

body {
  background-color: transparent;
  color: #999999;
  font-family: "SF Pro Display", Arial, sans-serif; }

p {
  line-height: 1.5; }

a {
  color: #063e3d; }

a:hover {
  color: #02100f; }

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 27px;
  color: #063e3d;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-weight: bold;
  text-rendering: optimizeLegibility;
  line-height: 1; }

h1, ._h1 {
  font-size: 42px; }

h2, ._h2, .Chamada .intro {
  font-size: 32px; }

h3, ._h3, .Slider._depoimentos .item .texto {
  font-size: 24px; }

h4, ._h4 {
  font-size: 20px; }

h5, ._h5, .Chamada .title, .Posts._noticias .post .title, .Slider._depoimentos .item .autor {
  font-size: 16px; }

h6, ._h6, .Slider._profissionais .item .cargo {
  font-size: 14px; }

h1 a, ._h1 a,
h2 a, ._h2 a, .Chamada .intro a,
h3 a, ._h3 a, .Slider._depoimentos .item .texto a,
h4 a, ._h4 a,
h5 a, ._h5 a, .Chamada .title a, .Posts._noticias .post .title a, .Slider._depoimentos .item .autor a,
h6 a, ._h6 a, .Slider._profissionais .item .cargo a {
  color: inherit; }

ul,
ul ul,
ul ol,
ol,
ol ul,
ol ol {
  margin: 0 0 0 27px; }

ol ol li {
  list-style-type: lower-alpha; }

ol ol ol li {
  list-style-type: lower-roman; }

nav ul,
nav ol {
  margin: 0;
  list-style: none; }

dl dt {
  font-weight: bold; }

dd {
  margin-left: 27px; }

p, blockquote, hr, pre, ol, ul, dl, table, fieldset, figure, address, form {
  margin-bottom: 27px; }

hr {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: -1px; }

blockquote {
  padding-left: 1rem;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
  font-style: italic;
  color: rgba(153, 153, 153, 0.65); }
  blockquote p {
    margin-bottom: .5rem; }

time, cite, small, figcaption {
  font-size: 87.5%; }

cite {
  opacity: .6; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted rgba(0, 0, 0, 0.5);
  cursor: help; }

var {
  font-size: 18px;
  opacity: .6;
  font-style: normal; }

mark, code, samp, kbd {
  position: relative;
  top: -1px;
  padding: 4px 4px 2px 4px;
  display: inline-block;
  line-height: 1;
  color: rgba(153, 153, 153, 0.85); }

kbd {
  border: 1px solid rgba(0, 0, 0, 0.1); }

sub,
sup {
  font-size: x-small;
  line-height: 0;
  margin-left: 1rem/4;
  position: relative; }

sup {
  top: 0;
  vertical-align: super; }

sub {
  bottom: 1px;
  vertical-align: sub; }

pre, code, samp, var, kbd {
  font-family: Consolas, Monaco, "Courier New", monospace; }

pre, code, samp, var, kbd, mark {
  font-size: 87.5%; }

pre,
pre code {
  background: #f8f8f8;
  padding: 0;
  top: 0;
  display: block;
  line-height: 1.5;
  color: rgba(153, 153, 153, 0.85);
  overflow: none;
  white-space: pre-wrap; }

pre {
  padding: 1rem; }

figcaption {
  opacity: .6; }

figure figcaption {
  position: relative;
  top: -1rem/2; }

figure pre {
  background: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px; }

figure .video-container,
figure pre {
  margin-bottom: 13.5px; }

._tal,
._text-left {
  text-align: left; }

._tac,
._text-center {
  text-align: center; }

._tar,
._text-right {
  text-align: right; }

ul._unstyled, .Pagination ul {
  margin-left: 0; }

ul._unstyled, .Pagination ul,
ul._unstyled ul, .Pagination ul ul {
  list-style: none; }

._monospace {
  font-family: Consolas, Monaco, "Courier New", monospace; }

._upper {
  text-transform: uppercase; }

._lower {
  text-transform: lowercase; }

em, ._italic {
  font-style: italic !important; }

strong, b, ._strong {
  font-weight: bold !important; }

._normal {
  font-weight: normal !important; }

._muted {
  opacity: .55; }

a._muted {
  color: #999999; }

a._muted:hover {
  opacity: 1; }

small, ._small {
  font-size: 16px; }

._end {
  margin-bottom: 0 !important; }

._nowrap,
._nowrap td {
  white-space: nowrap; }

._columns-2,
._columns-3,
._columns-4 {
  column-gap: 27px; }

._columns-2 {
  column-count: 2; }

._columns-3 {
  column-count: 3; }

._columns-4 {
  column-count: 4; }

.animated {
  animation-duration: 1s;
  animation-fill-mode: both; }

.animated.infinite {
  animation-iteration-count: infinite; }

.animated.hinge {
  animation-duration: 2s; }

.animated.flipOutX,
.animated.flipOutY,
.animated.bounceIn,
.animated.bounceOut {
  animation-duration: .75s; }

@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0); }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0); }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0); }
  90% {
    transform: translate3d(0, -4px, 0); } }

.bounce {
  animation-name: bounce;
  transform-origin: center bottom; }

@keyframes flash {
  from, 50%, to {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

.flash {
  animation-name: flash; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes pulse {
  from {
    transform: scale3d(1, 1, 1); }
  50% {
    transform: scale3d(1.05, 1.05, 1.05); }
  to {
    transform: scale3d(1, 1, 1); } }

.pulse {
  animation-name: pulse; }

@keyframes rubberBand {
  from {
    transform: scale3d(1, 1, 1); }
  30% {
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    transform: scale3d(1.05, 0.95, 1); }
  to {
    transform: scale3d(1, 1, 1); } }

.rubberBand {
  animation-name: rubberBand; }

@keyframes shake {
  from, to {
    transform: translate3d(0, 0, 0); }
  10%, 30%, 50%, 70%, 90% {
    transform: translate3d(-10px, 0, 0); }
  20%, 40%, 60%, 80% {
    transform: translate3d(10px, 0, 0); } }

.shake {
  animation-name: shake; }

@keyframes headShake {
  0% {
    transform: translateX(0); }
  6.5% {
    transform: translateX(-6px) rotateY(-9deg); }
  18.5% {
    transform: translateX(5px) rotateY(7deg); }
  31.5% {
    transform: translateX(-3px) rotateY(-5deg); }
  43.5% {
    transform: translateX(2px) rotateY(3deg); }
  50% {
    transform: translateX(0); } }

.headShake {
  animation-timing-function: ease-in-out;
  animation-name: headShake; }

@keyframes swing {
  20% {
    transform: rotate3d(0, 0, 1, 15deg); }
  40% {
    transform: rotate3d(0, 0, 1, -10deg); }
  60% {
    transform: rotate3d(0, 0, 1, 5deg); }
  80% {
    transform: rotate3d(0, 0, 1, -5deg); }
  to {
    transform: rotate3d(0, 0, 1, 0deg); } }

.swing {
  transform-origin: top center;
  animation-name: swing; }

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1); }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg); }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg); }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg); }
  to {
    transform: scale3d(1, 1, 1); } }

.tada {
  animation-name: tada; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes wobble {
  from {
    transform: none; }
  15% {
    transform: translate3d(-25%, 0, 0) rotate3d(0, 0, 1, -5deg); }
  30% {
    transform: translate3d(20%, 0, 0) rotate3d(0, 0, 1, 3deg); }
  45% {
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -3deg); }
  60% {
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 2deg); }
  75% {
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -1deg); }
  to {
    transform: none; } }

.wobble {
  animation-name: wobble; }

@keyframes jello {
  from, 11.1%, to {
    transform: none; }
  22.2% {
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    transform: skewX(-0.19531deg) skewY(-0.19531deg); } }

.jello {
  animation-name: jello;
  transform-origin: center; }

@keyframes bounceIn {
  from, 20%, 40%, 60%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  20% {
    transform: scale3d(1.1, 1.1, 1.1); }
  40% {
    transform: scale3d(0.9, 0.9, 0.9); }
  60% {
    opacity: 1;
    transform: scale3d(1.03, 1.03, 1.03); }
  80% {
    transform: scale3d(0.97, 0.97, 0.97); }
  to {
    opacity: 1;
    transform: scale3d(1, 1, 1); } }

.bounceIn {
  animation-name: bounceIn; }

@keyframes bounceInDown {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(0, -3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, 25px, 0); }
  75% {
    transform: translate3d(0, -10px, 0); }
  90% {
    transform: translate3d(0, 5px, 0); }
  to {
    transform: none; } }

.bounceInDown {
  animation-name: bounceInDown; }

@keyframes bounceInLeft {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  0% {
    opacity: 0;
    transform: translate3d(-3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(25px, 0, 0); }
  75% {
    transform: translate3d(-10px, 0, 0); }
  90% {
    transform: translate3d(5px, 0, 0); }
  to {
    transform: none; } }

.bounceInLeft {
  animation-name: bounceInLeft; }

@keyframes bounceInRight {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }

.bounceInRight {
  animation-name: bounceInRight; }

@keyframes bounceInUp {
  from, 60%, 75%, 90%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0); }
  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  75% {
    transform: translate3d(0, 10px, 0); }
  90% {
    transform: translate3d(0, -5px, 0); }
  to {
    transform: translate3d(0, 0, 0); } }

.bounceInUp {
  animation-name: bounceInUp; }

@keyframes bounceOut {
  20% {
    transform: scale3d(0.9, 0.9, 0.9); }
  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1); }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); } }

.bounceOut {
  animation-name: bounceOut; }

@keyframes bounceOutDown {
  20% {
    transform: translate3d(0, 10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.bounceOutDown {
  animation-name: bounceOutDown; }

@keyframes bounceOutLeft {
  20% {
    opacity: 1;
    transform: translate3d(20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.bounceOutLeft {
  animation-name: bounceOutLeft; }

@keyframes bounceOutRight {
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.bounceOutRight {
  animation-name: bounceOutRight; }

@keyframes bounceOutUp {
  20% {
    transform: translate3d(0, -10px, 0); }
  40%, 45% {
    opacity: 1;
    transform: translate3d(0, 20px, 0); }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.bounceOutUp {
  animation-name: bounceOutUp; }

@keyframes fadeIn {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

.fadeIn {
  animation-name: fadeIn; }

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDown {
  animation-name: fadeInDown; }

@keyframes fadeInDownBig {
  from {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInDownBig {
  animation-name: fadeInDownBig; }

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeft {
  animation-name: fadeInLeft; }

@keyframes fadeInLeftBig {
  from {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInLeftBig {
  animation-name: fadeInLeftBig; }

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRight {
  animation-name: fadeInRight; }

@keyframes fadeInRightBig {
  from {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInRightBig {
  animation-name: fadeInRightBig; }

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUp {
  animation-name: fadeInUp; }

@keyframes fadeInUpBig {
  from {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); }
  to {
    opacity: 1;
    transform: none; } }

.fadeInUpBig {
  animation-name: fadeInUpBig; }

@keyframes fadeOut {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

.fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOutDown {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0); } }

.fadeOutDown {
  animation-name: fadeOutDown; }

@keyframes fadeOutDownBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0); } }

.fadeOutDownBig {
  animation-name: fadeOutDownBig; }

@keyframes fadeOutLeft {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-100%, 0, 0); } }

.fadeOutLeft {
  animation-name: fadeOutLeft; }

@keyframes fadeOutLeftBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(-2000px, 0, 0); } }

.fadeOutLeftBig {
  animation-name: fadeOutLeftBig; }

@keyframes fadeOutRight {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0); } }

.fadeOutRight {
  animation-name: fadeOutRight; }

@keyframes fadeOutRightBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }

.fadeOutRightBig {
  animation-name: fadeOutRightBig; }

@keyframes fadeOutUp {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -100%, 0); } }

.fadeOutUp {
  animation-name: fadeOutUp; }

@keyframes fadeOutUpBig {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(0, -2000px, 0); } }

.fadeOutUpBig {
  animation-name: fadeOutUpBig; }

@keyframes flip {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, -360deg);
    animation-timing-function: ease-out; }
  40% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -190deg);
    animation-timing-function: ease-out; }
  50% {
    transform: perspective(400px) translate3d(0, 0, 150px) rotate3d(0, 1, 0, -170deg);
    animation-timing-function: ease-in; }
  80% {
    transform: perspective(400px) scale3d(0.95, 0.95, 0.95);
    animation-timing-function: ease-in; }
  to {
    transform: perspective(400px);
    animation-timing-function: ease-in; } }

.animated.flip {
  -webkit-backface-visibility: visible;
  backface-visibility: visible;
  animation-name: flip; }

@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  to {
    transform: perspective(400px); } }

.flipInX {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInX; }

@keyframes flipInY {
  from {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    animation-timing-function: ease-in;
    opacity: 0; }
  40% {
    transform: perspective(400px) rotate3d(0, 1, 0, -20deg);
    animation-timing-function: ease-in; }
  60% {
    transform: perspective(400px) rotate3d(0, 1, 0, 10deg);
    opacity: 1; }
  80% {
    transform: perspective(400px) rotate3d(0, 1, 0, -5deg); }
  to {
    transform: perspective(400px); } }

.flipInY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipInY; }

@keyframes flipOutX {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0; } }

.flipOutX {
  animation-name: flipOutX;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important; }

@keyframes flipOutY {
  from {
    transform: perspective(400px); }
  30% {
    transform: perspective(400px) rotate3d(0, 1, 0, -15deg);
    opacity: 1; }
  to {
    transform: perspective(400px) rotate3d(0, 1, 0, 90deg);
    opacity: 0; } }

.flipOutY {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  animation-name: flipOutY; }

@keyframes lightSpeedIn {
  from {
    transform: translate3d(100%, 0, 0) skewX(-30deg);
    opacity: 0; }
  60% {
    transform: skewX(20deg);
    opacity: 1; }
  80% {
    transform: skewX(-5deg);
    opacity: 1; }
  to {
    transform: none;
    opacity: 1; } }

.lightSpeedIn {
  animation-name: lightSpeedIn;
  animation-timing-function: ease-out; }

@keyframes lightSpeedOut {
  from {
    opacity: 1; }
  to {
    transform: translate3d(100%, 0, 0) skewX(30deg);
    opacity: 0; } }

.lightSpeedOut {
  animation-name: lightSpeedOut;
  animation-timing-function: ease-in; }

@keyframes rotateIn {
  from {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, -200deg);
    opacity: 0; }
  to {
    transform-origin: center;
    transform: none;
    opacity: 1; } }

.rotateIn {
  animation-name: rotateIn; }

@keyframes rotateInDownLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownLeft {
  animation-name: rotateInDownLeft; }

@keyframes rotateInDownRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInDownRight {
  animation-name: rotateInDownRight; }

@keyframes rotateInUpLeft {
  from {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; }
  to {
    transform-origin: left bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpLeft {
  animation-name: rotateInUpLeft; }

@keyframes rotateInUpRight {
  from {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0; }
  to {
    transform-origin: right bottom;
    transform: none;
    opacity: 1; } }

.rotateInUpRight {
  animation-name: rotateInUpRight; }

@keyframes rotateOut {
  from {
    transform-origin: center;
    opacity: 1; }
  to {
    transform-origin: center;
    transform: rotate3d(0, 0, 1, 200deg);
    opacity: 0; } }

.rotateOut {
  animation-name: rotateOut; }

@keyframes rotateOutDownLeft {
  from {
    transform-origin: left bottom;
    opacity: 1; }
  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, 45deg);
    opacity: 0; } }

.rotateOutDownLeft {
  animation-name: rotateOutDownLeft; }

@keyframes rotateOutDownRight {
  from {
    transform-origin: right bottom;
    opacity: 1; }
  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutDownRight {
  animation-name: rotateOutDownRight; }

@keyframes rotateOutUpLeft {
  from {
    transform-origin: left bottom;
    opacity: 1; }
  to {
    transform-origin: left bottom;
    transform: rotate3d(0, 0, 1, -45deg);
    opacity: 0; } }

.rotateOutUpLeft {
  animation-name: rotateOutUpLeft; }

@keyframes rotateOutUpRight {
  from {
    transform-origin: right bottom;
    opacity: 1; }
  to {
    transform-origin: right bottom;
    transform: rotate3d(0, 0, 1, 90deg);
    opacity: 0; } }

.rotateOutUpRight {
  animation-name: rotateOutUpRight; }

@keyframes hinge {
  0% {
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  20%, 60% {
    transform: rotate3d(0, 0, 1, 80deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out; }
  40%, 80% {
    transform: rotate3d(0, 0, 1, 60deg);
    transform-origin: top left;
    animation-timing-function: ease-in-out;
    opacity: 1; }
  to {
    transform: translate3d(0, 700px, 0);
    opacity: 0; } }

.hinge {
  animation-name: hinge; }

@keyframes jackInTheBox {
  from {
    opacity: 0;
    transform: scale(0.1) rotate(30deg);
    transform-origin: center bottom; }
  50% {
    transform: rotate(-10deg); }
  70% {
    transform: rotate(3deg); }
  to {
    opacity: 1;
    transform: scale(1); } }

.jackInTheBox {
  animation-name: jackInTheBox; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollIn {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0) rotate3d(0, 0, 1, -120deg); }
  to {
    opacity: 1;
    transform: none; } }

.rollIn {
  animation-name: rollIn; }

/* originally authored by Nick Pettit - https://github.com/nickpettit/glide */
@keyframes rollOut {
  from {
    opacity: 1; }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0) rotate3d(0, 0, 1, 120deg); } }

.rollOut {
  animation-name: rollOut; }

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  animation-name: zoomIn; }

@keyframes zoomInDown {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInDown {
  animation-name: zoomInDown; }

@keyframes zoomInLeft {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInLeft {
  animation-name: zoomInLeft; }

@keyframes zoomInRight {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInRight {
  animation-name: zoomInRight; }

@keyframes zoomInUp {
  from {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  60% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomInUp {
  animation-name: zoomInUp; }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  animation-name: zoomOut; }

@keyframes zoomOutDown {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutDown {
  animation-name: zoomOutDown; }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform-origin: left center; } }

.zoomOutLeft {
  animation-name: zoomOutLeft; }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  to {
    opacity: 0;
    transform: scale(0.1) translate3d(2000px, 0, 0);
    transform-origin: right center; } }

.zoomOutRight {
  animation-name: zoomOutRight; }

@keyframes zoomOutUp {
  40% {
    opacity: 1;
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  to {
    opacity: 0;
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform-origin: center bottom;
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1); } }

.zoomOutUp {
  animation-name: zoomOutUp; }

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInDown {
  animation-name: slideInDown; }

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInLeft {
  animation-name: slideInLeft; }

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInRight {
  animation-name: slideInRight; }

@keyframes slideInUp {
  from {
    transform: translate3d(0, 100%, 0);
    visibility: visible; }
  to {
    transform: translate3d(0, 0, 0); } }

.slideInUp {
  animation-name: slideInUp; }

@keyframes slideOutDown {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, 100%, 0); } }

.slideOutDown {
  animation-name: slideOutDown; }

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0); } }

.slideOutLeft {
  animation-name: slideOutLeft; }

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0); } }

.slideOutRight {
  animation-name: slideOutRight; }

@keyframes slideOutUp {
  from {
    transform: translate3d(0, 0, 0); }
  to {
    visibility: hidden;
    transform: translate3d(0, -100%, 0); } }

.slideOutUp {
  animation-name: slideOutUp; }

._invisible {
  visibility: hidden; }

._visible {
  visibility: visible; }

._display-block {
  display: block; }

._hide {
  display: none !important; }

@media (max-width: 768px) {
  ._hide-sm {
    display: none !important; } }

@media (min-width: 769px) {
  ._show-sm {
    display: none !important; } }

@media print {
  ._hide-print {
    display: none !important; }
  ._show-print {
    display: block !important; } }

._no-scroll {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100% !important;
  overflow: hidden; }

._video-container {
  height: 0;
  padding-bottom: 56.25%;
  position: relative;
  margin-bottom: 27px; }
  ._video-container iframe,
  ._video-container object,
  ._video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important; }

._mb0 {
  margin-bottom: 0px !important; }

._mb5 {
  margin-bottom: 5px !important; }

._mb10 {
  margin-bottom: 10px !important; }

._mb15 {
  margin-bottom: 15px !important; }

._mb20 {
  margin-bottom: 20px !important; }

._mb25 {
  margin-bottom: 25px !important; }

._mb30 {
  margin-bottom: 30px !important; }

._mb35 {
  margin-bottom: 35px !important; }

._mb40 {
  margin-bottom: 40px !important; }

._mb45 {
  margin-bottom: 45px !important; }

._mb50 {
  margin-bottom: 50px !important; }

._mb55 {
  margin-bottom: 55px !important; }

._mb60 {
  margin-bottom: 60px !important; }

._mb65 {
  margin-bottom: 65px !important; }

._mb70 {
  margin-bottom: 70px !important; }

._mb75 {
  margin-bottom: 75px !important; }

._mb80 {
  margin-bottom: 80px !important; }

._mb85 {
  margin-bottom: 85px !important; }

._mb90 {
  margin-bottom: 90px !important; }

._mb95 {
  margin-bottom: 95px !important; }

._mb100 {
  margin-bottom: 100px !important; }

._group:after {
  content: '';
  display: table;
  clear: both; }

._float-right {
  float: right; }

._float-left {
  float: left; }

@media (max-width: 768px) {
  ._float-right {
    float: none; }
  ._float-left {
    float: none; } }

._fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; }

._mid, .Form ._modal-form-success .text {
  position: absolute;
  top: 50%;
  transform: perspective(1px) translateY(-50%); }

._w5 {
  width: 5%; }

._w10 {
  width: 10%; }

._w15 {
  width: 15%; }

._w20 {
  width: 20%; }

._w25 {
  width: 25%; }

._w30 {
  width: 30%; }

._w35 {
  width: 35%; }

._w40 {
  width: 40%; }

._w45 {
  width: 45%; }

._w50 {
  width: 50%; }

._w55 {
  width: 55%; }

._w60 {
  width: 60%; }

._w65 {
  width: 65%; }

._w70 {
  width: 70%; }

._w75 {
  width: 75%; }

._w80 {
  width: 80%; }

._w85 {
  width: 85%; }

._w90 {
  width: 90%; }

._w95 {
  width: 95%; }

._w100 {
  width: 100%; }

._w-auto {
  width: auto; }

.Breadcrumbs {
  font-size: 16px;
  margin-bottom: 1.5; }
  .Breadcrumbs ul {
    display: flex;
    align-items: center; }
  .Breadcrumbs._centered ul {
    justify-content: center; }
  .Breadcrumbs span,
  .Breadcrumbs a {
    font-style: normal;
    padding: 0 10px;
    display: inline-block;
    white-space: nowrap; }
  .Breadcrumbs li:after {
    display: inline-block;
    content: '/';
    color: rgba(0, 0, 0, 0.3); }
  .Breadcrumbs li:last-child:after {
    display: none; }
  .Breadcrumbs li:first-child span,
  .Breadcrumbs li:first-child a {
    padding-left: 0; }
  .Breadcrumbs li._active a {
    color: #999999;
    text-decoration: none;
    cursor: text; }

.Button {
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  border: 1px solid transparent;
  border-radius: 3px;
  background-color: #063e3d;
  color: #063e3d;
  font-size: 16px;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-weight: bold;
  line-height: 38px;
  text-decoration: none;
  text-align: center;
  cursor: pointer;
  vertical-align: middle;
  transition: background 200ms ease;
  -webkit-appearance: none; }
  .Button:hover {
    outline: none;
    text-decoration: none;
    color: #063e3d;
    background-color: #02100f; }
  .Button:disabled, .Button._disabled {
    background-color: rgba(6, 62, 61, 0.7);
    color: #063e3d;
    cursor: default;
    font-style: normal; }
  .Button._outline {
    border-width: 2px;
    border-color: #063e3d;
    background: none;
    color: #063e3d; }
    .Button._outline:hover {
      border-color: #02100f;
      background: none;
      color: #02100f; }
    .Button._outline:disabled, .Button._outline._disabled {
      border-color: rgba(6, 62, 61, 0.5);
      background: none;
      color: rgba(6, 62, 61, 0.7); }
  .Button._raised {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3); }
  .Button._upper {
    text-transform: uppercase;
    letter-spacing: .04em; }
  .Button._rounded {
    border-radius: 20px; }
  .Button._secondary {
    background-color: #D4C9B5;
    color: #D4C9B5; }
    .Button._secondary:hover {
      background-color: #c1b195;
      color: #D4C9B5; }
    .Button._secondary:disabled, .Button._secondary._disabled {
      background-color: rgba(212, 201, 181, 0.7);
      color: rgba(212, 201, 181, 0.7); }
    .Button._secondary._outline {
      border-color: #D4C9B5;
      background: none;
      color: #D4C9B5; }
      .Button._secondary._outline:hover {
        border-color: #c1b195;
        color: #c1b195; }
      .Button._secondary._outline:disabled, .Button._secondary._outline._disabled {
        border-color: rgba(212, 201, 181, 0.5);
        background: none;
        color: rgba(212, 201, 181, 0.7); }

.Form {
  margin-bottom: 27px; }
  .Form fieldset {
    margin-bottom: 2rem;
    padding: 2rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    font-family: inherit; }
    .Form fieldset legend {
      position: relative;
      margin-left: -1rem;
      padding: 0 1rem;
      font-weight: bold;
      font-size: 16px;
      text-transform: uppercase;
      line-height: 0; }
  .Form .form-item {
    margin-bottom: 27px; }
    .Form .form-item label {
      display: block;
      color: #999999;
      margin-bottom: 4px;
      font-size: 17px; }
      .Form .form-item label._checkbox {
        color: inherit;
        font-size: 18px;
        font-weight: normal;
        text-transform: none;
        line-height: 1.5;
        cursor: pointer; }
        .Form .form-item label._checkbox input {
          margin-top: 0; }
    .Form .form-item > div._append, .Form .form-item > div._prepend {
      display: flex; }
      .Form .form-item > div._append input, .Form .form-item > div._prepend input {
        flex: 1; }
      .Form .form-item > div._append .Button,
      .Form .form-item > div._append span, .Form .form-item > div._prepend .Button,
      .Form .form-item > div._prepend span {
        flex-shrink: 0; }
      .Form .form-item > div._append span, .Form .form-item > div._prepend span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: normal;
        border: 1px solid #d4d4d4;
        background-color: #f8f8f8;
        padding: 0 .875rem;
        color: rgba(0, 0, 0, 0.5);
        font-size: 16px;
        white-space: nowrap; }
    .Form .form-item > div._prepend input {
      border-radius: 0 3px 3px 0; }
    .Form .form-item > div._prepend .Button {
      margin-right: -1px;
      border-radius: 3px 0 0 3px !important; }
    .Form .form-item > div._prepend span {
      border-right: none;
      border-radius: 3px 0 0 3px; }
    .Form .form-item > div._append input {
      border-radius: 3px 0 0 3px; }
    .Form .form-item > div._append .Button {
      margin-left: -1px;
      border-radius: 0 3px 3px 0 !important; }
    .Form .form-item > div._append span {
      border-left: none;
      border-radius: 0 3px 3px 0; }
    .Form .form-item._checkboxes label.checkbox {
      display: inline-block;
      margin-right: 16px; }
    .Form .form-item._buttons button,
    .Form .form-item._buttons .Button {
      margin-right: 8px; }
  .Form._horizontal .form-item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; }
    .Form._horizontal .form-item label {
      width: 30%;
      line-height: 40px; }
    .Form._horizontal .form-item > div {
      width: 70%; }
    @media (max-width: 768px) {
      .Form._horizontal .form-item {
        display: block; }
        .Form._horizontal .form-item label,
        .Form._horizontal .form-item > div {
          width: 100%; } }
  .Form._inline input,
  .Form._inline textarea,
  .Form._inline select {
    display: inline-block;
    width: auto; }

input,
textarea,
select {
  display: block;
  width: 100%;
  height: 40px;
  padding: 0 12px;
  border: 1px solid #d4d4d4;
  border-radius: 3px;
  background-color: #fff;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-size: 16px;
  outline: none;
  vertical-align: middle;
  box-shadow: none; }
  input:focus,
  textarea:focus,
  select:focus {
    border-color: #3498db;
    box-shadow: 0 0 1px #3498db inset;
    outline: none; }
  input._error,
  textarea._error,
  select._error {
    border: 1px solid #ef8b80;
    background-color: rgba(231, 76, 60, 0.1); }
    input._error:focus,
    textarea._error:focus,
    select._error:focus {
      border-color: #e74c3c;
      box-shadow: 0 0 1px #e74c3c inset; }
  input._success,
  textarea._success,
  select._success {
    border: 1px solid #69dd9a;
    background-color: rgba(46, 204, 113, 0.1); }
    input._success:focus,
    textarea._success:focus,
    select._success:focus {
      border-color: #2ecc71;
      box-shadow: 0 0 1px #2ecc71 inset; }
  input:disabled, input._disabled,
  textarea:disabled,
  textarea._disabled,
  select:disabled,
  select._disabled {
    resize: none;
    opacity: 0.6;
    cursor: default;
    font-style: italic;
    color: rgba(0, 0, 0, 0.5); }

input[type="submit"] {
  width: auto; }

input[type="file"] {
  display: inline-block;
  width: auto;
  height: auto;
  border: none;
  padding: 0;
  background: none;
  box-shadow: none; }

input[type="search"], input._search {
  padding-left: 32px;
  background-repeat: no-repeat;
  background-position: 8px 53%;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="#000" fill-opacity="0.4" d="M14.891,14.39l-0.5.5a0.355,0.355,0,0,1-.5,0L9.526,10.529a5.3,5.3,0,1,1,2.106-4.212,5.268,5.268,0,0,1-1.1,3.21l4.362,4.362A0.354,0.354,0,0,1,14.891,14.39ZM6.316,2.418a3.9,3.9,0,1,0,3.9,3.9A3.9,3.9,0,0,0,6.316,2.418Z"/></svg>'); }

input[type="radio"], input[type="checkbox"] {
  display: inline-block;
  width: auto;
  height: auto;
  padding: 0; }
  input[type="radio"]:focus, input[type="checkbox"]:focus {
    box-shadow: none; }

textarea {
  height: auto;
  padding: 8px 12px;
  vertical-align: top; }

select {
  -webkit-appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12"><path fill="#5e6c75" d="M0.722,4.823L-0.01,4.1,4.134-.01,4.866,0.716Zm7.555,0L9.01,4.1,4.866-.01l-0.732.726ZM0.722,7.177L-0.01,7.9,4.134,12.01l0.732-.726Zm7.555,0L9.01,7.9,4.866,12.01l-0.732-.726Z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 1rem center; }

select[multiple] {
  height: auto;
  padding: .5rem .75rem;
  background-image: none; }

.Row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .Row {
      flex-direction: column;
      flex-wrap: nowrap; } }
  .Row ._col-1 {
    width: 8.33333%; }
  .Row ._offset-1 {
    margin-left: 8.33333%; }
  .Row ._col-2 {
    width: 16.66667%; }
  .Row ._offset-2 {
    margin-left: 16.66667%; }
  .Row ._col-3 {
    width: 25%; }
  .Row ._offset-3 {
    margin-left: 25%; }
  .Row ._col-4 {
    width: 33.33333%; }
  .Row ._offset-4 {
    margin-left: 33.33333%; }
  .Row ._col-5 {
    width: 41.66667%; }
  .Row ._offset-5 {
    margin-left: 41.66667%; }
  .Row ._col-6 {
    width: 50%; }
  .Row ._offset-6 {
    margin-left: 50%; }
  .Row ._col-7 {
    width: 58.33333%; }
  .Row ._offset-7 {
    margin-left: 58.33333%; }
  .Row ._col-8 {
    width: 66.66667%; }
  .Row ._offset-8 {
    margin-left: 66.66667%; }
  .Row ._col-9 {
    width: 75%; }
  .Row ._offset-9 {
    margin-left: 75%; }
  .Row ._col-10 {
    width: 83.33333%; }
  .Row ._offset-10 {
    margin-left: 83.33333%; }
  .Row ._col-11 {
    width: 91.66667%; }
  .Row ._offset-11 {
    margin-left: 91.66667%; }
  .Row ._col-12 {
    width: 100%; }
  .Row ._offset-12 {
    margin-left: 100%; }
  .Row._gutters > ._col-1 {
    width: calc(8.33333% - 60px); }
  .Row._gutters > ._offset-1 {
    margin-left: calc(8.33333% + 60px) !important; }
  .Row._gutters > ._col-2 {
    width: calc(16.66667% - 60px); }
  .Row._gutters > ._offset-2 {
    margin-left: calc(16.66667% + 60px) !important; }
  .Row._gutters > ._col-3 {
    width: calc(25% - 60px); }
  .Row._gutters > ._offset-3 {
    margin-left: calc(25% + 60px) !important; }
  .Row._gutters > ._col-4 {
    width: calc(33.33333% - 60px); }
  .Row._gutters > ._offset-4 {
    margin-left: calc(33.33333% + 60px) !important; }
  .Row._gutters > ._col-5 {
    width: calc(41.66667% - 60px); }
  .Row._gutters > ._offset-5 {
    margin-left: calc(41.66667% + 60px) !important; }
  .Row._gutters > ._col-6 {
    width: calc(50% - 60px); }
  .Row._gutters > ._offset-6 {
    margin-left: calc(50% + 60px) !important; }
  .Row._gutters > ._col-7 {
    width: calc(58.33333% - 60px); }
  .Row._gutters > ._offset-7 {
    margin-left: calc(58.33333% + 60px) !important; }
  .Row._gutters > ._col-8 {
    width: calc(66.66667% - 60px); }
  .Row._gutters > ._offset-8 {
    margin-left: calc(66.66667% + 60px) !important; }
  .Row._gutters > ._col-9 {
    width: calc(75% - 60px); }
  .Row._gutters > ._offset-9 {
    margin-left: calc(75% + 60px) !important; }
  .Row._gutters > ._col-10 {
    width: calc(83.33333% - 60px); }
  .Row._gutters > ._offset-10 {
    margin-left: calc(83.33333% + 60px) !important; }
  .Row._gutters > ._col-11 {
    width: calc(91.66667% - 60px); }
  .Row._gutters > ._offset-11 {
    margin-left: calc(91.66667% + 60px) !important; }
  .Row._gutters > ._col-12 {
    width: calc(100% - 60px); }
  .Row._gutters > ._offset-12 {
    margin-left: calc(100% + 60px) !important; }
  @media (max-width: 768px) {
    .Row .col[class^='_offset-'], .Row .col[class*=' _offset-'] {
      margin-left: 0; } }
  .Row .col._first {
    order: -1; }
  .Row .col._last {
    order: 1; }
  .Row._gutters,
  .Row._gutters > .Row {
    margin-left: -60px; }
    @media (max-width: 768px) {
      .Row._gutters,
      .Row._gutters > .Row {
        margin-left: 0; } }
    .Row._gutters > .col,
    .Row._gutters > .Row > .col {
      margin-left: 60px; }
      @media (max-width: 768px) {
        .Row._gutters > .col,
        .Row._gutters > .Row > .col {
          margin-left: 0; } }
  .Row._auto .col {
    flex: 1 1 0%; }
  @media (max-width: 768px) {
    .Row .col {
      margin-left: 0;
      width: 100%;
      margin-bottom: 27px; }
      .Row .col:last-child {
        margin-bottom: 0; }
      .Row .col._first-sm {
        order: -1; }
      .Row .col._last-sm {
        order: 1; }
      .Row .col._first {
        order: 0; }
      .Row .col._last {
        order: 0; }
    .Row._gutters > .col,
    .Row._gutters > .Row > .col {
      width: 100%;
      margin: 0 0 27px; }
      .Row._gutters > .col:last-child,
      .Row._gutters > .Row > .col:last-child {
        margin: 0; } }

.Message {
  position: relative;
  padding: 1rem;
  padding-right: 2.5em;
  padding-bottom: .75rem;
  margin-bottom: 27px;
  background: #eee;
  color: #999999;
  font-family: "SF Pro Display", Arial, sans-serif;
  font-size: 16px;
  line-height: 1.5; }
  .Message a {
    color: inherit; }
  .Message p,
  .Message h1,
  .Message h2,
  .Message h3,
  .Message h4,
  .Message h5,
  .Message h6 {
    margin-bottom: 0; }
  .Message._error {
    background: #e74c3c;
    color: #fff; }
  .Message._success {
    background: #2ecc71;
    color: #fff; }
  .Message._warning {
    background: #f7ca18; }
  .Message._focus {
    background: #3498db;
    color: #fff; }

.Pagination {
  margin: 27px 0;
  font-size: 16px; }
  .Pagination ul {
    display: flex;
    margin: 0; }
    .Pagination ul li {
      margin: 0 1px; }
      .Pagination ul li span,
      .Pagination ul li a {
        display: inline-block;
        padding: 8px 12px;
        border: 1px solid transparent;
        border-radius: 3px;
        line-height: 1;
        white-space: nowrap; }
      .Pagination ul li a {
        color: #999999;
        text-decoration: none; }
        .Pagination ul li a:hover {
          border-color: #eee;
          color: rgba(0, 0, 0, 0.5); }
      .Pagination ul li span,
      .Pagination ul li._active a {
        color: rgba(0, 0, 0, 0.5);
        border-color: #eee;
        cursor: text; }
  .Pagination._centered ul {
    justify-content: center; }
  .Pagination._pager li {
    flex-basis: 50%; }
    .Pagination._pager li.next {
      text-align: right; }
    .Pagination._pager li a {
      padding-left: 16px;
      padding-right: 16px;
      border-radius: 64px;
      border-color: rgba(0, 0, 0, 0.1); }
  .Pagination._pager._centered li {
    flex-basis: auto;
    margin-left: 4px;
    margin-right: 4px; }

.Table {
  max-width: 100%;
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 16px;
  line-height: 1.5;
  empty-cells: show; }
  .Table caption {
    color: rgba(153, 153, 153, 0.5);
    text-align: left;
    font-size: 16px;
    font-weight: 500; }
  .Table th {
    text-align: left;
    font-weight: 700;
    vertical-align: bottom; }
  .Table td {
    vertical-align: top; }
  .Table th,
  .Table td {
    padding: 1rem 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); }
    .Table th:first-child,
    .Table td:first-child {
      padding-left: 0; }
    .Table th:last-child,
    .Table td:last-child {
      padding-right: 0; }
  .Table tfoot th,
  .Table tfoot td {
    color: rgba(153, 153, 153, 0.5); }
  .Table._bordered td,
  .Table._bordered th {
    border: 1px solid rgba(0, 0, 0, 0.05); }
  .Table._striped tr:nth-child(odd) td {
    background: #f8f8f8; }
  .Table._bordered td:first-child,
  .Table._bordered th:first-child, .Table._striped td:first-child,
  .Table._striped th:first-child {
    padding-left: 1rem; }
  .Table._bordered td:last-child,
  .Table._bordered th:last-child, .Table._striped td:last-child,
  .Table._striped th:last-child {
    padding-right: 1rem; }
  .Table._unstyled td, .Pagination ul.Table td,
  .Table._unstyled th, .Pagination ul.Table th {
    border: none;
    padding: 0; }
  .Table tr._align-middle td,
  .Table td._align-middle {
    vertical-align: middle; }

._darkred {
  color: #063e3d !important; }

._navy {
  color: #122747 !important; }

._papaya {
  color: #D4C9B5 !important; }

._bg_darkred {
  background-color: #063e3d !important; }

._bg_navy {
  background-color: #122747 !important; }

._bg_papaya {
  background-color: #D4C9B5 !important; }

.container {
  position: relative;
  width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .container {
      width: 992px; } }
  @media (max-width: 992px) {
    .container {
      width: 768px; } }
  @media (max-width: 768px) {
    .container {
      width: 90%; } }
  .container._narrow {
    width: 840px; }
    @media (max-width: 992px) {
      .container._narrow {
        width: 90%; } }

body._toggled {
  height: 100vh;
  overflow: hidden; }
  body._toggled .Header .Nav .menu {
    display: block; }
  body._toggled .Header .toggle {
    top: 20px !important; }

._padded, .Chamada {
  padding: 90px 0; }
  @media (max-width: 768px) {
    ._padded, .Chamada {
      padding: 70px 0; } }

._mb470 {
  margin-bottom: 470px; }

._mt70 {
  margin-top: 70px; }

._desloca1 {
  margin-top: -180px; }

._desloca2 {
  margin-top: -270px; }

._desloca_foto {
  margin-top: 220px; }

h1, ._h1 {
  font-weight: 200;
  line-height: 1.2; }

h2, h3, h4, h5, h6,
._h2,
.Chamada .intro, ._h3, .Slider._depoimentos .item .texto, ._h4, ._h5, .Chamada .title, .Posts._noticias .post .title, .Slider._depoimentos .item .autor, ._h6, .Slider._profissionais .item .cargo {
  line-height: 2; }

p {
  line-height: 40px; }

.mapa {
  height: 600px; }

.blocker .modal.atividades {
  display: inline-block; }

.modal.atividades {
  display: none;
  padding: 50px 30px;
  width: 760px;
  color: #999999;
  border-radius: 0; }
  .modal.atividades p {
    margin: 0; }
  .modal.atividades a.close-modal {
    display: none !important; }
  .modal.atividades .close {
    position: absolute;
    right: 15px;
    top: 0;
    color: #999999;
    font-size: 25px !important;
    text-decoration: none; }
  @media (max-width: 992px) {
    .modal.atividades {
      width: 90% !important; } }

.local .mapa {
  margin-bottom: 60px;
  height: 400px; }

.local p {
  color: #063e3d; }

.banner-middle {
  width: 100%;
  height: 500px;
  background-position: center; }
  @media (max-width: 768px) {
    .banner-middle {
      height: 350px; } }

@media (max-width: 768px) {
  .article-subtitle {
    font-size: 18px; } }

@media (max-width: 768px) {
  h1, ._h1 {
    font-size: 40px; }
  ._desloca2 {
    margin-top: -220px; }
  ._mb70 {
    margin-bottom: 30px !important; }
  p {
    font-size: 16px; } }

@media (max-width: 480px) {
  h1, ._h1 {
    font-size: 30px; } }

.Acompanhamento {
  margin-bottom: 145px; }
  .Acompanhamento p {
    margin: 0;
    color: #063e3d; }
  .Acompanhamento .bar {
    margin: 35px 0;
    width: 100%;
    height: 10px;
    border-radius: 50px;
    background-color: #DBDBDB; }
    .Acompanhamento .bar .progress {
      height: 100%;
      background-color: #122747;
      border-radius: 50px; }

@media (max-width: 768px) {
  .Acompanhamento {
    margin-bottom: 80px; } }

.Atividades .texto {
  padding: 80px;
  padding-left: 0; }

.Atividades .subtitle {
  line-height: 1.6; }

.Atividades .imagem {
  left: 50%;
  width: 80%;
  margin-left: -50%;
  background-size: cover;
  background-position: center; }

.Atividades ._atividades_sem {
  padding: 50px 0 30px; }
  .Atividades ._atividades_sem .texto2 {
    padding: 30px 80px; }
    .Atividades ._atividades_sem .texto2:nth-child(2n+1) {
      padding-left: 0; }
    .Atividades ._atividades_sem .texto2:nth-child(2n+2) {
      padding-right: 0; }

.Atividades .col:nth-child(4n+3).texto {
  margin-left: 50%;
  padding-left: 80px;
  padding-right: 0; }

.Atividades .col:nth-child(4n+4).imagem {
  right: 30%;
  left: auto;
  margin-left: -100%; }

.Atividades .imagem-full .container {
  padding-top: 700px; }
  .Atividades .imagem-full .container .title {
    position: absolute;
    top: 30%;
    right: 0;
    width: 810px;
    max-width: 100%;
    color: #fff;
    z-index: 1;
    transform: perspective(1px) translateY(-50%); }

@media (max-width: 992px) {
  .Atividades {
    padding: 70px 0 0; }
    .Atividades .Row .col {
      margin-bottom: 24px; }
      .Atividades .Row .col:nth-child(4n+3) {
        margin-left: 0;
        padding-left: 0; }
      .Atividades .Row .col:nth-child(4n+4).imagem {
        right: auto;
        margin-left: auto; }
    .Atividades .Row ._col-6 {
      width: 100%; }
    .Atividades .texto {
      padding: 0; }
    .Atividades .imagem {
      margin-left: 0;
      left: auto;
      padding-top: 70%; }
    .Atividades ._atividades_sem {
      padding: 20px 0 0; }
      .Atividades ._atividades_sem .texto2 {
        padding: 0; }
    .Atividades .subtitle {
      font-size: 27px; }
    .Atividades .imagem-full .container {
      padding-top: 420px; }
      .Atividades .imagem-full .container .title {
        top: 40%;
        font-size: 30px; } }

.Button {
  height: 60px;
  padding-left: 55px;
  padding-right: 55px;
  line-height: 60px; }
  .Button._rounded {
    border-radius: 60px; }
  .Button._outline {
    background: #fff !important; }
  .Button._transparent {
    background-color: transparent !important; }

.Chamada .title {
  margin-bottom: 0; }

.Chamada .intro {
  line-height: 1.4;
  margin-bottom: 0; }

.Chamada._quemsomos {
  background: no-repeat right 0 top 0;
  background-size: contain; }
  .Chamada._quemsomos .img {
    position: absolute;
    top: 0;
    right: -200px;
    height: 100%;
    background-size: cover;
    background-position: center;
    width: 50%;
    opacity: 0;
    z-index: 0; }
  .Chamada._quemsomos .container {
    z-index: 1; }
    .Chamada._quemsomos .container .title, .Chamada._quemsomos .container .intro {
      color: #D4C9B5; }
    .Chamada._quemsomos .container .title {
      margin-bottom: 140px; }
    .Chamada._quemsomos .container .intro {
      width: 510px;
      max-width: 49%; }
    .Chamada._quemsomos .container .Button {
      position: absolute;
      bottom: 0;
      right: 0; }

.Chamada._atividades:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50vw;
  height: 100%;
  background: #D4C9B5;
  z-index: -1; }

.Chamada._atividades .intro {
  position: absolute;
  bottom: 0;
  font-size: 25px; }

.Chamada._atividades p {
  color: #063e3d; }

.Chamada._atividades .Row .col:first-child {
  padding-right: 120px; }

.Chamada._atividades .Row .col:last-child {
  padding-left: 120px; }
  .Chamada._atividades .Row .col:last-child .Button {
    position: absolute;
    bottom: 0; }

.Chamada._ajuda {
  background-color: #122747; }
  .Chamada._ajuda .title, .Chamada._ajuda p {
    color: #D4C9B5; }
  .Chamada._ajuda .intro {
    margin-bottom: 80px; }

@media (max-width: 992px) {
  .Chamada._quemsomos .container .Button {
    position: relative;
    margin-top: 24px; } }

@media (max-width: 768px) {
  .Chamada._quemsomos .container .title {
    margin-bottom: 50px; }
  .Chamada._quemsomos .container .intro {
    width: 100%;
    max-width: 100%;
    font-size: 27px; }
  .Chamada._quemsomos .img {
    position: relative;
    width: 100%;
    height: 300px;
    right: 0 !important;
    opacity: 1 !important;
    margin-top: 40px; }
  .Chamada._atividades:before {
    width: 100%; }
  .Chamada._ajuda .intro {
    margin-bottom: 50px;
    font-size: 27px; } }

.Contatos {
  list-style: none;
  margin: 0; }
  .Contatos li {
    line-height: 40px; }
    .Contatos li:before {
      display: inline-block;
      width: 50px;
      color: #063e3d;
      font-family: "fontello";
      font-size: 18px;
      font-style: normal;
      font-weight: normal; }
  .Contatos .email a {
    color: #999999;
    text-decoration: none; }
    .Contatos .email a:hover {
      color: #063e3d; }
  .Contatos .email:before {
    content: '\f0e0'; }
  .Contatos .telefones:before {
    content: '\e801'; }
  .Contatos .whatsapp:before {
    content: '\f232'; }

@media (max-width: 480px) {
  .Contatos li {
    font-size: 15px; } }

.Footer {
  padding: 90px 0;
  background-color: #063e3d; }
  .Footer p, .Footer a {
    color: #fff;
    font-size: 16px;
    text-decoration: none; }

@media (max-width: 1200px) {
  .Footer p {
    font-size: 15px; } }

.Form ._modal-form-success {
  position: fixed;
  top: 3%;
  left: 3%;
  width: 94%;
  height: 94%;
  border-radius: 0;
  text-align: center;
  z-index: 99999;
  transition: 200ms; }
  .Form ._modal-form-success .text {
    width: 100%;
    left: 0; }
  .Form ._modal-form-success .modal-close {
    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
    height: 20px;
    cursor: pointer; }
    .Form ._modal-form-success .modal-close:before {
      transform: rotate(45deg); }
    .Form ._modal-form-success .modal-close:after {
      transform: rotate(-45deg); }
    .Form ._modal-form-success .modal-close:before, .Form ._modal-form-success .modal-close:after {
      content: '';
      position: absolute;
      top: 0;
      left: 9px;
      display: block;
      height: 20px;
      width: 1px;
      background-color: #333;
      opacity: 0.5; }
    .Form ._modal-form-success .modal-close:hover:before, .Form ._modal-form-success .modal-close:hover:after {
      opacity: 1; }

.Form label span.text-danger {
  opacity: 0.3; }

.Form input, .Form select, .Form textarea {
  margin-bottom: 40px;
  padding: 0;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #999999;
  background-color: transparent; }

.Form button.Button {
  background: transparent;
  padding: 0;
  float: right;
  text-transform: lowercase; }
  .Form button.Button .icon-right {
    position: relative;
    top: 1px;
    font-size: 16px;
    padding: 0; }

.Form#newsletter label {
  color: #fff; }

.Form#newsletter input, .Form#newsletter select, .Form#newsletter textarea {
  border-bottom: 1px solid #fff;
  color: #fff; }

.Form#newsletter button.Button {
  margin-top: -92px;
  color: #D4C9B5;
  font-size: 20px; }

.Form#empreendimento h1 {
  margin: 55px 0 60px;
  color: #D4C9B5; }

.Galeria {
  margin: 50px 0 0; }
  .Galeria .col {
    margin-bottom: 60px;
    height: 190px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .Galeria._empreendimentos .open {
    display: block;
    height: 400px;
    background-size: cover;
    background-position: center; }
  .Galeria._empreendimentos .tab {
    margin-bottom: 0;
    height: 114px; }
  .Galeria .owl-nav {
    position: absolute;
    top: 50%;
    width: 100%;
    height: 60px;
    transform: translateY(-50%); }
    .Galeria .owl-nav .owl-prev, .Galeria .owl-nav .owl-next {
      position: absolute;
      top: 0;
      width: 60px;
      height: 60px;
      color: #063e3d;
      background-color: #D4C9B5;
      font-size: 20px;
      border-radius: 50%; }
      .Galeria .owl-nav .owl-prev i, .Galeria .owl-nav .owl-next i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
    .Galeria .owl-nav .owl-prev {
      left: -30px; }
    .Galeria .owl-nav .owl-next {
      right: -30px; }

@media (max-width: 768px) {
  .Galeria._empreendimentos .open {
    height: 350px; }
  .Galeria._empreendimentos .Row {
    display: none; } }

.Header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 160px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  z-index: 10; }
  .Header .toggle {
    display: none;
    position: absolute;
    top: 20px;
    right: 0;
    width: 40px;
    height: 40px;
    color: #D4C9B5;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    z-index: 15; }
    .Header .toggle i {
      position: absolute;
      right: 3px;
      top: 5px; }

.noticias .Header {
  border-bottom: 1px solid rgba(120, 59, 59, 0.1);
  background-color: #D4C9B5; }
  .noticias .Header .toggle {
    color: #063e3d; }

@media (max-width: 768px) {
  .Header {
    height: 80px; }
    .Header .toggle {
      display: block; } }

.Hero {
  width: 100%;
  height: 560px;
  padding-top: 330px;
  background: transparent no-repeat center;
  background-size: cover; }
  .Hero h1 {
    color: #D4C9B5; }
  .Hero .categoria {
    position: absolute;
    top: -90px;
    display: inline-block;
    padding: 20px 40px;
    color: #fff;
    background-color: #D4C9B5;
    font-weight: 700;
    text-transform: lowercase; }

.noticias .Hero {
  height: 480px;
  padding-top: 240px;
  background-color: #D4C9B5; }
  .noticias .Hero._mb {
    margin-bottom: 350px; }
  .noticias .Hero._single {
    margin-bottom: 210px; }

@media (max-width: 768px) {
  .Hero {
    height: 500px; }
  .noticias .Hero {
    padding-top: 160px;
    padding: 160px 0 20px;
    height: auto; }
    .noticias .Hero._mb {
      margin-bottom: 0; }
    .noticias .Hero._single {
      margin-bottom: 0; } }

.Landing_page .formulario .titulo {
  position: relative;
  padding: 40px 70px;
  margin-bottom: 0;
  background-color: #063e3d;
  color: #fff;
  text-align: center;
  z-index: 1; }
  .Landing_page .formulario .titulo:after {
    content: '';
    position: absolute;
    left: calc(50% - 50px);
    bottom: -55px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 55px 50px 0 50px;
    border-color: #063e3d transparent transparent transparent; }
  @media (max-width: 992px) {
    .Landing_page .formulario .titulo {
      padding: 20px; }
      .Landing_page .formulario .titulo:after {
        left: calc(50% - 40px);
        bottom: -46px;
        border-width: 46px 40px 0 40px; } }

.Landing_page .formulario .info {
  background-color: #eee;
  padding: 100px 50px 40px; }
  @media (max-width: 992px) {
    .Landing_page .formulario .info {
      padding: 60px 20px 20px; } }
  .Landing_page .formulario .info .download {
    font-size: 30px;
    text-align: center; }
    .Landing_page .formulario .info .download a {
      color: #063e3d;
      font-weight: bold;
      text-decoration: none; }
    .Landing_page .formulario .info .download i {
      display: block;
      margin: 0 auto; }

.Landing_page .formulario .Form label {
  display: none; }

.Landing_page .formulario .Form._success,
.Landing_page .formulario .Message {
  display: none; }

.Landing_page .video_embed {
  position: relative;
  width: 100%; }
  .Landing_page .video_embed:after {
    content: '';
    display: block;
    width: 100%;
    padding-top: 56.2%; }
  .Landing_page .video_embed iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.Logo {
  position: relative;
  display: block;
  float: left;
  width: 120px;
  margin: 0;
  padding: 0;
  background: url(../images/logo_grb.png) no-repeat center;
  background-size: contain;
  font-size: 1px;
  text-indent: -9999px;
  overflow: hidden; }
  .Logo:after {
    content: '';
    display: block;
    padding-top: 50%; }
  .Logo a {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none; }

.Header .Logo {
  position: absolute;
  top: 50px;
  left: 0; }

.noticias .Logo {
  background-image: url(../images/logo_grb_dark.png); }

.noticias .Footer .Logo {
  background-image: url(../images/logo_grb.png); }

@media (max-width: 768px) {
  .Header .Logo {
    top: 13px;
    width: 100px; } }

.Nav .menu li, .Nav .social li {
  float: left; }
  .Nav .menu li a, .Nav .social li a {
    text-decoration: none; }

.Header .Nav .menu {
  float: right; }
  .Header .Nav .menu li {
    position: relative;
    height: 160px;
    margin-left: 40px;
    line-height: 160px; }
    .Header .Nav .menu li._active:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 4px;
      border-radius: 4px 4px 0 0;
      background: #D4C9B5;
      z-index: 1; }
    .Header .Nav .menu li a {
      color: #D4C9B5;
      font-size: 16px;
      text-decoration: none; }

.Footer .menu {
  float: left; }
  .Footer .menu li {
    height: 60px;
    margin-right: 30px;
    line-height: 60px; }
    .Footer .menu li a {
      color: #D4C9B5;
      font-size: 16px; }

.Footer .social {
  float: right; }
  .Footer .social li {
    height: 60px;
    margin-left: 60px;
    line-height: 60px; }
    .Footer .social li a {
      color: #D4C9B5;
      font-size: 26px; }
      .Footer .social li a span {
        display: none; }

.noticias .Header .Nav .menu li._active:after {
  background-color: #063e3d; }

.noticias .Header .Nav .menu li a {
  color: #063e3d; }

@media (max-width: 992px) {
  .Header .Nav .menu li {
    margin-left: 25px; }
  .Footer .menu li {
    margin-right: 14px;
    font-size: 15px; }
  .Footer .social li {
    margin-left: 30px; } }

@media (max-width: 768px) {
  .Header .Nav .menu {
    position: fixed;
    display: none;
    padding: 70px 0 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    text-align: center;
    z-index: 10; }
    .Header .Nav .menu li {
      display: block;
      margin: 0;
      float: none;
      height: 60px !important;
      line-height: 60px !important;
      text-align: center; }
      .Header .Nav .menu li._active:after {
        display: none; }
      .Header .Nav .menu li._active a {
        font-weight: 700; }
  .Footer {
    padding: 70px 0; }
    .Footer .menu {
      float: none; }
      .Footer .menu li {
        display: block;
        margin: 0;
        float: none; }
    .Footer ._mb100 {
      margin-bottom: 40px !important; }
    .Footer .social {
      float: left; }
      .Footer .social li {
        margin: 0 30px 0 0; } }

.Posts._noticias .post {
  margin-bottom: 100px; }
  .Posts._noticias .post .foto {
    margin-bottom: 50px; }
    .Posts._noticias .post .foto img {
      position: relative;
      z-index: 0; }
    .Posts._noticias .post .foto .Tag {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1; }
  .Posts._noticias .post .title {
    margin-bottom: 50px;
    font-weight: normal; }
  .Posts._noticias .post .link {
    font-weight: bold;
    text-decoration: none;
    text-transform: lowercase; }
    .Posts._noticias .post .link .icon-right {
      font-size: 18px; }

.Posts._empreendimentos .post {
  margin-bottom: 80px; }
  .Posts._empreendimentos .post a {
    text-decoration: none; }
  .Posts._empreendimentos .post .imagem {
    margin-bottom: 45px;
    height: 620px;
    background-size: cover;
    background-position: center; }
    .Posts._empreendimentos .post .imagem .cat {
      position: absolute;
      bottom: 0;
      padding: 20px 40px;
      color: #fff;
      background-color: #D4C9B5;
      font-weight: 700;
      text-transform: lowercase; }
  .Posts._empreendimentos .post .title {
    margin-bottom: 50px; }
  .Posts._empreendimentos .post .link {
    font-weight: bold;
    text-decoration: none;
    text-transform: lowercase; }
    .Posts._empreendimentos .post .link .icon-right {
      font-size: 18px; }

@media (max-width: 1200px) {
  .Posts._empreendimentos .post .imagem {
    height: 490px; } }

@media (max-width: 992px) {
  .Posts._empreendimentos .post .imagem {
    height: 370px; } }

@media (max-width: 768px) {
  .Posts._empreendimentos .post {
    margin-bottom: 40px; }
    .Posts._empreendimentos .post .imagem {
      height: 300px; } }

.Row._doublespaced {
  margin-left: -120px; }
  .Row._doublespaced .col {
    margin-left: 120px; }
    .Row._doublespaced .col._col-6 {
      width: calc(50% - 120px); }

@media (max-width: 768px) {
  .Row._doublespaced {
    margin-left: 0; }
    .Row._doublespaced .col {
      margin-left: 0; }
      .Row._doublespaced .col._col-6 {
        width: 100%; } }

.Slider {
  width: 100%; }
  .Slider .item {
    display: block;
    width: 100%; }
  .Slider .owl-dots {
    position: absolute; }
    .Slider .owl-dots .owl-dot {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-right: 30px;
      border: 1px solid #D4C9B5;
      border-radius: 5px; }
      .Slider .owl-dots .owl-dot.active {
        background-color: #D4C9B5; }
  .Slider._bannerhome .item {
    height: 800px;
    background: transparent center center no-repeat;
    background-size: cover; }
    .Slider._bannerhome .item .container {
      height: 100%; }
      .Slider._bannerhome .item .container .title {
        position: absolute;
        top: 300px;
        width: 550px; }
      .Slider._bannerhome .item .container .link {
        position: absolute;
        bottom: 80px;
        right: 0;
        color: #fff;
        font-size: 14px;
        font-weight: bold;
        text-decoration: none;
        line-height: 60px; }
        .Slider._bannerhome .item .container .link .icon-right {
          display: inline-block;
          width: 60px;
          height: 60px;
          margin-left: 40px;
          border: 1px solid #D4C9B5;
          border-radius: 30px;
          color: #D4C9B5;
          font-size: 18px;
          text-align: center;
          line-height: 60px;
          transition: all 100ms ease-in; }
        .Slider._bannerhome .item .container .link:hover .icon-right {
          background-color: #D4C9B5;
          margin-left: 30px;
          color: #fff; }
  .Slider._bannerhome .owl-dots {
    left: 50%;
    margin-left: -600px;
    bottom: 105px; }
  .Slider._bannerhome:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 50%;
    height: 16px;
    background-color: #063e3d; }
  .Slider._atividades .item {
    height: 380px; }
  .Slider._atividades .owl-dots {
    right: 50%;
    top: 0;
    margin-right: 120px; }
    .Slider._atividades .owl-dots .owl-dot {
      border-color: #063e3d; }
      .Slider._atividades .owl-dots .owl-dot.active {
        background-color: #063e3d; }
  .Slider._profissionais {
    margin-top: 80px; }
    .Slider._profissionais .item .Row .col:last-child {
      padding-top: 100px; }
    .Slider._profissionais .item .cargo, .Slider._profissionais .item .nome, .Slider._profissionais .item .texto {
      color: #063e3d; }
    .Slider._profissionais .item .cargo {
      margin-bottom: 10px; }
    .Slider._profissionais .item .nome {
      margin-bottom: 10px;
      font-size: 33px; }
    .Slider._profissionais .item .texto {
      margin-bottom: 0;
      font-size: 15px; }
    .Slider._profissionais .owl-dots {
      position: relative;
      left: 0;
      bottom: 0;
      margin-top: 50px; }
      .Slider._profissionais .owl-dots .owl-dot {
        border-color: #063e3d; }
        .Slider._profissionais .owl-dots .owl-dot.active {
          background-color: #063e3d; }
  .Slider._depoimentos {
    min-height: 670px;
    background: transparent no-repeat center center;
    background-size: cover; }
    .Slider._depoimentos .item .texto {
      max-width: 840px;
      margin-bottom: 60px;
      color: #D4C9B5; }
    .Slider._depoimentos .item .autor {
      margin-bottom: 0;
      color: #D4C9B5; }
  .Slider._noticias .item .foto {
    position: absolute;
    top: 0;
    right: 54.1vw;
    width: 45.8vw;
    max-width: 660px;
    height: 100%;
    background: center center no-repeat;
    background-size: cover;
    z-index: 1; }
    .Slider._noticias .item .foto img {
      display: none; }
  .Slider._noticias .item .col {
    height: 480px; }
  .Slider._noticias .item .link {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 14px;
    font-weight: bold;
    text-decoration: none;
    line-height: 60px; }
    .Slider._noticias .item .link .icon-right {
      display: inline-block;
      width: 60px;
      height: 60px;
      margin-left: 40px;
      border: 1px solid #063e3d;
      border-radius: 30px;
      color: #063e3d;
      font-size: 18px;
      text-align: center;
      line-height: 60px;
      transition: all 100ms ease-in; }
    .Slider._noticias .item .link:hover .icon-right {
      background-color: #063e3d;
      margin-left: 30px;
      color: #fff; }
  .Slider._noticias .owl-dots {
    left: 50%;
    bottom: 20px; }
    .Slider._noticias .owl-dots .owl-dot {
      border-color: #063e3d; }
      .Slider._noticias .owl-dots .owl-dot.active {
        background-color: #063e3d; }

@media (max-width: 1200px) {
  .Slider._bannerhome .owl-dots {
    margin-left: -490px; }
  .Slider._atividades .owl-dots {
    margin-right: 10px; } }

@media (max-width: 992px) {
  .Slider._bannerhome .owl-dots {
    margin-left: -380px; }
  .Slider._atividades .Row .col:first-child {
    padding-right: 50px; }
  .Slider._atividades .intro {
    position: relative;
    margin-top: 20px; }
  .Slider._atividades .owl-dots {
    top: -80px; } }

@media (max-width: 768px) {
  .Slider._bannerhome .item {
    padding-top: 110px;
    height: 500px; }
    .Slider._bannerhome .item .container .title {
      position: relative;
      top: 0;
      width: 100%; }
    .Slider._bannerhome .item .container .link {
      position: relative;
      bottom: 0; }
  .Slider._bannerhome .owl-dots {
    margin: 0;
    left: auto;
    bottom: 30px;
    width: 100%;
    text-align: center; }
    .Slider._bannerhome .owl-dots .owl-dot:last-child {
      margin: 0; }
  .Slider._atividades .Row .col:first-child {
    padding-right: 0; }
  .Slider._atividades .Row .col:last-child {
    padding-left: 0; }
  .Slider._atividades .item {
    height: auto; }
  .Slider._atividades .intro {
    font-size: 27px; }
  .Slider._atividades .owl-dots {
    position: relative;
    top: auto;
    right: auto;
    text-align: center; }
    .Slider._atividades .owl-dots .owl-dot:last-child {
      margin: 0; }
  .Slider._profissionais {
    margin-top: 40px; }
    .Slider._profissionais .item .Row .col:last-child {
      padding-top: 0; }
  .Slider._noticias .Tag {
    margin: 20px 0 40px !important; }
  .Slider._noticias .item .foto {
    display: block;
    position: relative;
    right: auto;
    width: 100%;
    max-width: 100%;
    height: 400px; }
  .Slider._noticias .item .col {
    height: auto; }
  .Slider._noticias .item .link {
    position: relative;
    display: block;
    margin-bottom: 20px; }
  .Slider._noticias .owl-dots {
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
    text-align: center; }
    .Slider._noticias .owl-dots .owl-dot:last-child {
      margin: 0; } }

.Tag {
  display: inline-block;
  padding: 27px 38px;
  font-size: 14px;
  font-weight: bold;
  text-transform: lowercase; }
  .Tag._white {
    background-color: #fff;
    color: #063e3d; }
  .Tag._papaya {
    background-color: #D4C9B5;
    color: #fff !important; }
  .Tag._darkred {
    background-color: #063e3d;
    color: #fff !important; }

.Video {
  margin: 50px 0 0;
  height: 450px; }
  .Video iframe {
    width: 100%;
    height: 100%; }

@media (max-width: 768px) {
  .Video {
    height: 270px; } }
